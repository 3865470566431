body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  /* background-image: url("assets/images/background-desktop.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  position: relative; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: #0F192E; /* Override default color inheritance */
}

.notification-provider {
  width: 400px;
  max-width: 400px;
  top: calc(var(--mantine-header-height, 0px) + 24px);
  right: 24px;
}
